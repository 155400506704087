/**
 * the xhr plugin
 *
 * @module extensions
 * @class $app.fn.xhr
 * @constructor
 */
$app.extend('xhr', function() {

  $.ajaxSetup({
    headers: { 'x-csrf-token' : $('meta[name="csrf-token"]').attr('content') }
  });

  /**
   * contains default options
   *
   * @for $app.fn.xhr
   * @property options
   * @private
   * @type {Object}
   */
  var options = {

    /**
     * data attributes on the source node
     *
     * @for $app.fn.xhr
     * @property options.selectorSource
     * @default data-xhr
     * @type {String}
     * @private
     */
    selectorSource: 'data-xhr',

    /**
     * data attributes on the target node
     *
     * @for $app.fn.xhr
     * @property options.selectorTarget
     * @default data-xhr-response
     * @type {String}
     * @private
     */
    selectorTarget: 'data-xhr-response',

    /**
     * the css class name if is active
     *
     * @for $app.fn.xhr
     * @property options.toggleActive
     * @default active
     * @type {String}
     * @private
     */
    toggleActive:   'active',

    /**
     * the css class name if is inactive
     *
     * @for $app.fn.xhr
     * @property options.toggleInactive
     * @default inactive
     * @type {String}
     * @private
     */
    toggleInactive: 'inactive'
  };

  /**
   * get the target by name
   *
   * @for $app.fn.xhr
   * @method getTargetByName
   * @param  {String}            name        the name of the target
   * @return {String}
   * @private
   */
  var getTargetByName = function(name) {
    return $('[' + options.selectorTarget + '="' + name + '"]');
  };

  /**
   * get the target by name
   *
   * @for $app.fn.xhr
   * @method getTargetByName
   * @param  {JQueryElement}    el        the target jquery element
   * @return {JQueryElement}
   * @private
   */
  var getTargetByElement = function(el) {
    return getTargetByName(el.data('xhr'));
  };

  // public methods
  return {

    /**
     * called after init
     *
     * @for $app.fn.xhr
     * @method afterInit
     * @param  {JQueryElement}    body        jquery element witch is the current parent
     * @return {String}
     * @public
     */
    afterInit: function(body) {
      // if opener url is defined, we refresh also the opener
      $app.dom.each('[data-xhr-autoload]', function() {
        var el = $(this);
        var opener = el.data('xhr-autoload');
        if (typeof opener == 'undefined') return;
        $app.fn.xhr.get(opener.href, $app.fn.xhr.targetByName(opener.target), function() {
          // prevent multiple callings
          el.removeAttr('data-xhr-autoload');
        });
      });
    },

    /**
     * register delegated events
     *
     * @for $app.fn.xhr
     * @method delegate
     * @param  {JQueryElement}    node        jquery element witch is the current parent
     * @return {Void}
     * @public
     */
    delegate: function(node) {
      // listen on clicked a-links in a data-xhr-group container
      $app.dom.listen('[data-xhr-group] a', 'click', function(evt) {
        var el = $(evt.target);
        if ('a' !== evt.target.tagName.toLowerCase()) {
          el = $(el.parents('a'));
        }
        var wrapper = $(el.parents('[data-xhr-group]'));
        evt.preventDefault();

        var node = getTargetByName(wrapper.data('xhr-group'));
        $app.fn.xhr.get(el.attr('href'), node);
      });

      // listen on data-xhr attribute
      $app.dom.listen('[data-xhr]', 'click', function(evt) {
        var el = $(evt.target);
        if (!el.data('xhr')) el = el.parent('[data-xhr]');
        if (!el.data('xhr')) return;
        // stop default event
        evt.preventDefault();
        // toggle link container class
        $app.fn.xhr.toggle(el);
        var href = (el.data('href')) ? el.data('href') : el.attr('href');
        if (!href) return;
        var node = getTargetByElement(el);
        return $app.fn.xhr.get(href, node, function() {
          // data-xhr-modal="close"
          if ('close' == el.data('xhr-modal')) $app.fn.modal.destroy();
        });
      });

      // listen on forms which contains data-xhr attribute
      $app.dom.listen('[data-xhr]', 'submit', function(evt) {
        var el = $(evt.target);
        evt.preventDefault();
        return $app.fn.xhr.post(el);
      });

      // listen on forms which contains data-xhr attribute
      $app.dom.listen('[data-submit]', 'click', function(evt) {
        var el = $(evt.target);
        // stop default event
        evt.preventDefault();
        var href = (el.data('href')) ? el.data('href') : el.attr('href');
        if (!href) return;

        var form = $(el.parents('form'));
        form.prop('action', href);

        var closeModal = ('close' == el.data('xhr-modal')) ? true : false;
        return $app.fn.xhr.post(form, null, closeModal);
      });


      var trigger = function(evt) {
        var el = $(evt.target).parents('form');
        var node = $app.fn.xhr.targetByName(el.data('xhr'));
        var url = el.data('sync');
        if (typeof url == 'undefined') url = el.attr('action');
        $.ajax({
          url: url,
          method: el.attr('method'),
          data: el.serializeArray(),
          complete: function(xhr, status) {
            if (xhr.status === 422) {
              return $app.fn.xhr.failedWithErrors(xhr.responseJSON);
            }
            node.html($(xhr.responseText));
            if (typeof callback == 'function') callback(data);
            $app.init(node);
          }
        });
      }

      // sync fields, used for goblin forms
      $app.dom.listen('[data-trigger]', 'change', function(evt) {
        trigger(evt);
      });
      $app.dom.listen('[data-trigger]', 'ifChecked', function(evt) {
        trigger(evt);
      });
      $app.dom.listen('[data-trigger]', 'ifUnchecked', function(evt) {
        trigger(evt);
      });
    },

    /**
     * do a GET xhr request
     *
     * @for $app.fn.xhr
     * @method get
     * @param  {String}            href        the request url
     * @param  {JQueryElement}     node        the target node to replace the innerHMTL
     * @param  {Function}          callback    a callback function to execute after request is completed
     * @return {Void}
     * @public
     */
    get: function(href, node, callback) {
      if (!node.length || !href.length) return;
      var loading = node.find('[data-xhr-loading]');
      loading.addClass('xhr-loading');

      $.get(href, function(response) {
        node.html(response);
        $app.init(node);
        loading.removeClass('xhr-loading');
        if (typeof callback == 'function') callback(response);
      })
    },

    /**
     * do a POST xhr request
     *
     * @for $app.fn.xhr
     * @method post
     * @param  {JQueryElement}     el           the jquery form element
     * @param  {Function}          callback     a callback function to execute after request is completed
     * @param  {Boolean}           closeModal   if false, the we do not destroy the modal   (default is TRUE)
     * @return {Void}
     * @public
     */
    post: function(el, callback, closeModal) {
      var node = getTargetByElement(el);
      if (typeof closeModal == 'undefined') {
        closeModal = ('persist' == el.data('xhr-modal')) ? false : true;
      }
      $.ajax({
        url: el.attr('action'),
        method: el.attr('method'),
        data: el.serializeArray(),
        complete: function(xhr, status) {
          if (xhr.status === 401) {
            return location.href='/login';
          }
          if (xhr.status === 422) {
            return $app.fn.xhr.failedWithErrors(xhr.responseJSON, el);
          }
          node.html($(xhr.responseText));
          if (typeof callback == 'function') callback(data);
          $app.init(node);
          if (true === closeModal) $app.fn.modal.destroy();
        }
      });
    },

    /**
     * handle the response status codes and additional messages
     *
     * @for $app.fn.xhr
     * @method failedWithErrors
     * @param  Object            json         the response json
     * @param  {JQueryElement}   el           the jquery form element
     * @return {Void}
     * @public
     */
    failedWithErrors: function(json, node) {
      if (json.length == 0) return;
      var list = '';
      $.each(json, function(key, value) {
        if (key == '_xhr') {
          $.each(value, function(idx, id) {
            var ico = $('[data-xhr-error-ico="' + id + '"]');
            var parent = $('[data-xhr-error="' + id + '"]');
            if (parent.length > 0) {
              parent.addClass('has-feedback has-error');
              if (!ico.length) {
                parent.append($('<span data-xhr-error-ico="' + id + '" class="form-control-feedback fa fa-remove" aria-hidden="true"></span>'));
              }
            }
          });
        } else {
          list += '<li><span>' + value[0] + '</span></li>';
        }
      });
      if (list.length) {
        if (typeof node == 'undefined') node = $('body');
        $app.fn.alert.error('<ul class="list-unstyled">{0}</ul>'.format(list))
        $(node.find('[data-role="messages"]')).html('<div class="alert alert-danger"><ul class="list-unstyled">{0}</ul></div>'.format(list));
      }
    },

    /**
     * toggle the active status of updated element
     *
     * @for $app.fn.xhr
     * @method toggle
     * @param  {JQueryElement}    el    the parent element
     * @return {Void}
     * @public
     */
    toggle: function(el) {
      var toggle = el.data('toggle-active');
      if (toggle) {
        $(toggle).removeClass(options.toggleActive).addClass(options.toggleInactive);
        el.parents(toggle).addClass(options.toggleActive);
      }
    },

    /**
     * targetByName
     *
     * @for $app.fn.xhr
     * @method targetByName
     * @param  {String}            name       the name of the target
     * @return {JQueryElement}                the target element as jquery object
     * @public
     */
    targetByName: function(name) {
      return getTargetByName(name);
    }
  };
});