/**
 * returns a string produced according to the formatting string
 * here is a example for argument/placeholder swapping:
 *
 * @for String
 * @method format
 * @return {String}       return a formatted string
 * @example
 * var foo = 'say {0} {1}!';
 * var bar = 'hello';
 * var baz = 'world';
 * foo.format(bar, baz);    // = "say hello world!"
 */
String.prototype.format = function() {
  var args = arguments;
  return this.replace(/{(\d+)}/g, function(match, number) {
    // maybe return var "match" instead ''?
    return (typeof args[number] !== 'undefined') ? args[number] : '';
  });
};

 // IIFE - Immediately Invoked Function Expression
$(function($, window, document) {
  // the $ is now locally scoped
  // listen for the jquery ready event on the document
  $(function() {
    // the dom is ready
    $app.run('application runtime');
  });

  /**
   * the application
   *
   * @module app
   * @class $app
   * @constructor
   */
   $app = (function() {

    /**
     * contains the response body
     *
     * @for $app
     * @property body
     * @private
     * @type {Object}
     */
    var body = null;

    // public methods
    return {

      /**
       * initialized flag
       *
       * @for $app
       * @property initialized
       * @default false
       * @return void
       * @type {Boolean}
       * @public
       */
      initialized: false,

      /**
       * write a fancy log on console
       *
       * @for $app
       * @method log
       * @param  {String}  msg    the message
       * @param  {Object}  css    object with style css properties
       * @return {Void}
       * @public
       */
      log: function(msg, css) {
        var styles = '';
        if (css) {
          for (property in css) {
            if (css.hasOwnProperty(property)) styles += '{0}:{1};'.format(property, css[property])
          }
          msg = '\n%c' + msg + '\n';
        }
        console.log(msg, styles);
      },

      /**
       * run the application
       *
       * @for $app
       * @method run
       * @param  {String}    msg    a additional log message
       * @return {Void}
       * @public
       */
      run: function(msg) {
        this.log('run application', {
          'padding': '2px',
          'background-color': '#0b479d',
          'color': '#fff'
        });
        console.time(msg);

        body = $('body');
        this.init(body);
        this.once();
        this.delegate(body);

        if (!this.initialized) {
          this.log('initialize finished', {
            'padding': '2px',
            'background-color': '#fff',
            'color': '#666',
            'text-decoration': 'underline',
            'font-weight': 'bold'
          });
          this.initialized = true;
        }
        console.timeEnd(msg);
      },

      /**
       * execute this method only once for each extended plugin on calling $app.run()
       *
       * @for $app
       * @method once
       * @return {Void}
       * @public
       */
      once: function() {
        for (var name in this.fn) {
          if (typeof this.fn[name].once != 'undefined') {
            try {
              this.fn[name].once(body);
            } catch(e) {
              return console.error(name, e);
            }
          }
        }
      },

      /**
       * init each extended plugin
       *
       * @for $app
       * @method init
       * @param  {Object}  body    the response body or default the document body as jquery object
       * @return {Void}
       * @public
       */
      init: function(body) {
        for (var name in this.fn) {
          if (typeof this.fn[name].init != 'undefined') {
            try {
              this.fn[name].init(body);
              if (!this.initialized) this.log('init: {0}'.format(name));
            } catch(e) {
              return console.error(name, e);
            }
          }
        }
        if (this.initialized) {
          this.log('reinitialize selector: {0}'.format(body.selector), {
            'padding': '2px',
            'background-color': '#fff',
            'color': '#c870d0'
          });
        }
        this.afterInit(body);
      },

      /**
       * called after init for each extended plugin, see $app.init()
       *
       * @for $app
       * @method afterInit
       * @param  {Object}  body    the response body or default the document body as jquery object
       * @return {Void}
       * @public
       */
      afterInit: function(body) {
        for (var name in this.fn) {
          if (typeof this.fn[name].afterInit != 'undefined') {
            try {
              this.fn[name].afterInit(body);
              if (!this.initialized) this.log('afterInit: {0}'.format(name));
            } catch(e) {
              return console.error(name, e);
            }
          }
        }
      },

      /**
       * delegate all events and triggers for each plugin
       *
       * @for $app
       * @method delegate
       * @param  {Object}    body    the response body or default the document body as jquery object
       * @return {Void}
       * @public
       */
      delegate: function(body) {
        for (var name in this.fn) {
          if (typeof this.fn[name].delegate != 'undefined') {
            try {
              this.fn[name].delegate(body);
              if (!this.initialized) this.log('delegate: ' + name);
            } catch(e) {
              console.error(name, e);
            }
          }
        }
      },

      /**
       * extend $app.fn with new plugin
       *
       * @for $app
       * @method extend
       * @param  {String}  name    the plugin name
       * @param  {Object}  object  the plugin object
       * @return {Void}
       * @public
       */
      extend: function(name, object) {
        this.fn[name] = object();
      },

      /**
       * plugin container which holds the registerd plugins
       *
       * @for $app
       * @property fn
       * @type {Object}
       * @public
       */
      fn: {},

      /**
       * common library container which contains helpful methods
       *
       * @for $app
       * @property lib
       * @type {Object}
       * @public
       */
      lib: {

        /**
         * merge two objects
         *
         * @for $app.lib
         * @method merge
         * @param  {Object}  first
         * @param  {Object}  second
         * @return {Object}
         * @public
         */
        merge: function(first, second) {
          return $.extend(true, first, second);
        }
      },

      /**
       * dom library container which contains helpful methods
       *
       * @for $app
       * @property dom
       * @type {Object}
       * @public
       */
      dom: {

        /**
         * iterate dom tree by given selector
         *
         * @for $app.dom
         * @method each
         * @param  {String}    selector      the css selector
         * @param  {Function}  callback      the callback to execute for each match
         * @return {Void}
         * @public
         */
        each: function(selector, callback) {
          body.find(selector).each(callback);
        },

        /**
         * iterate dom tree by given selector
         *
         * @for $app.dom
         * @method listen
         * @param  {String}    selector      the css selector
         * @param  {String}    type          the event type to listen, e.g. 'click', 'change', ...
         * @param  {Function}  callback      the callback to execute for each match
         * @return {Void}
         * @public
         */
        listen: function(selector, type, callback) {
          body.on(type, selector, callback);
        },

        /**
         * get all delegated events on document body
         *
         * @for $app.dom
         * @method getEvents
         * @return {Object}
         * @public
         */
        getEvents: function() {
          return $._data(document.body, 'events');
        }
      }
    };
  })();
}(window.jQuery, window, document));