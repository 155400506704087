/**
 * the modal plugin
 *
 * @module extensions
 * @class $app.fn.modal
 * @constructor
 */
$app.extend('modal', function() {

  /**
   * contains default options
   *
   * @for $app.fn.modal
   * @property options
   * @private
   * @type {Object}
   */
  var options = {

    /**
     * @for $app.fn.modal
     * @property options.backdrop
     * @default true
     * @type {Boolean}
     * @private
     */
    backdrop: true,

    /**
     * @for $app.fn.modal
     * @property options.keyboard
     * @default true
     * @type {Boolean}
     * @private
     */
    keyboard: true,

    /**
     * @for $app.fn.modal
     * @property options.show
     * @default true
     * @type {Boolean}
     * @private
     */
    show: true,

    /**
     * css class, used on data attribute possible values: "modal-lg" or "modal-sm"
     *
     * @example
     *    data-modal='{"cls":"modal-lg"}' for large modals and data-modal='{"cls":"modal-sm"}' for small modals
     * @for $app.fn.modal
     * @property options.cls
     * @default true
     * @type {Boolean}
     * @private
     */
    cls: '',

    append: null,
  };

  // public methods
  return {

    /**
     * register delegated events
     *
     * @for $app.fn.modal
     * @method delegate
     * @param  {JQueryElement}    node        jquery element witch is the current parent
     * @return {Void}
     * @public
     */
    delegate: function(body) {
      $app.dom.listen('[data-modal]', 'click', function(evt) {
        var el = $(evt.target);
        if (!el.data('modal')) el = el.parent('[data-modal]');
        if (!el.data('modal')) return;
        // stop default event
        evt.preventDefault();
        // get href
        var href = (el.data('href')) ? el.data('href') : el.attr('href');
        if (!href) return;
        var properties = $app.lib.merge(options, el.data('modal'));

        var tpl   = $('#template-modal').html();
        var modal = $(tpl).modal(properties);

        modal.find('.modal-dialog').addClass(properties.cls);
        modal.find('.modal-title').html(el.attr('title'));
        var modalBody = modal.find('.modal-body');
        if (properties.append) body = $app.fn.xhr.targetByName(properties.append);

        modalBody.load(href, function() {
          body.append(modal);
          $app.init(modal);
        });
        modal.on('hide.bs.modal', function () {
          $('#modal').remove();
        });
        modal.on('hidden.bs.modal', function () {
          $('#modal').removeData('bs.modal')
        });
     });

      // $app.dom.listen('[data-modal]', 'hidden.bs.modal', function(evt) {});
    },

    /**
     * destroy a modal
     *
     * @for $app.fn.modal
     * @method destroy
     * @return {Void}
     * @public
     */
    destroy: function() {
      $( '.modal' ).modal( 'hide' ).data( 'bs.modal', null );
      setTimeout(function(){
        $('#modal').remove();
      }, 1000);
    }
  };
});
